export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/pinocchios-logo.png',
    banner_img_root : '/img/pinocchios-banner.png',
    resid : '',
    key_value : 'pinocchiosfrederick',
    secret_value : 'pinocchiosfrederick',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.48868330993!2d-104.9851146846141!3d40.08681617940482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c090975f8e811%3A0xe0372a14628bf714!2s3768%20State%20Hwy%2052%2C%20Frederick%2C%20CO%2080516%2C%20USA!5e0!3m2!1sen!2sin!4v1596182369622!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Pinocchios Frederick Order Online"
};
